import { defineComponent } from 'vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { ProjectStageStatusEnum } from '@/core/packages/shared-library';
export default defineComponent({
    name: 'approve-release-payment-stage',
    emits: ['close', 'approve-release-project-stage-deposit'],
    props: {
        show: Boolean,
        loading: Boolean,
        selectedProjectStage: Object,
        projectQuote: Object,
        selectedProject: Object,
    },
    components: {
        BaseModal,
    },
    data: () => {
        return {
            ProjectStageStatusEnum,
            submittingForm: false,
        };
    },
    computed: {
        totalStageCost() {
            if (!this.selectedProjectStage) {
                return 0;
            }
            return this.selectedProjectStage.stageCost + this.selectedProjectStage.projectStageServiceFee;
        },
        stageIndex() {
            if (!this.selectedProjectStage) {
                return 0;
            }
            return this.projectQuote.projectStages.indexOf(this.selectedProjectStage);
        },
    },
    methods: {
        cancel() {
            this.$emit('close');
        },
        submit() {
            this.$emit('approve-release-project-stage-deposit');
        },
    }
});

import { Delete, Document, Picture, Plus } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import ImageDynamicLayout from '@/core/components/ui/ImageDynamicLayout.vue';
import ImageFailedLoading from '@/core/components/ui/ImageFailedLoading.vue';
import ImageLoading from '@/core/components/ui/ImageLoading.vue';
import { ImageFileTypes } from '@/core/helpers/file.helper';
import { ProjectStageStatusEnum, ProjectStageTypeEnum } from '@/core/packages/shared-library';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { PROJECT_STAGES_CACHE } from '@/store/modules/attachment-cache/constants';
import { PROJECT_STAGES_TASKS_STORE } from '@/store/modules/project-quotes/submodules/project-stages-tasks';
import { AUTH_TOKEN } from '../../../core/constants';
import { getLocalStorageWithExpiry } from '../../../core/utils/common';
import { PROJECT_QUOTES_STORE } from '../../../store/modules/project-quotes';
export default defineComponent({
    name: 'stage-box',
    emits: [
        'update-project-stage-status',
        'update-project-stage-task-status',
        'upload-project-stage-attachment',
        'remove-project-stage-attachment',
        'view-project-stage-invoice'
    ],
    props: {
        projectStage: Object,
        projectQuote: Object,
        projectStageServiceFee: Number,
        stageIndex: Number,
        verifyingStage: {
            type: Number,
            default: 0,
        }
    },
    components: {
        ImageDynamicLayout,
        ImageLoading,
        ImageFailedLoading,
        Plus,
        Delete,
        Document,
        Picture
    },
    data: () => {
        return {
            ProjectStageTypeEnum,
            ProjectStageStatusEnum,
            attachments: [],
            attachmentUrls: [],
            apiUrl: process.env.VUE_APP_API_URL,
            loadingAttachments: false,
            totalServiceFee: 0,
            totalStageCost: 0,
            imageAttachments: [],
            documentAttachments: []
        };
    },
    computed: {
        ...mapGetters(['isProjectOwner', 'isTradesperson']),
        ...mapGetters(PROJECT_QUOTES_STORE, ['getProjectStageDurationDays']),
        uploadHeaders() {
            const token = getLocalStorageWithExpiry(AUTH_TOKEN);
            return {
                Authorization: `Bearer ${token}`
            };
        },
        totalStageCostWithServiceFee() {
            return this.projectStage.stageCost + this.projectStageServiceFee;
        },
        previewImages() {
            return this.imageAttachments.map((attachment) => attachment.src);
        },
        getUploadUrl() {
            const component = this;
            const { $props } = component;
            const { id: projectQuoteId } = $props.projectQuote;
            const { id: projectStageId } = $props.projectStage;
            const { apiUrl } = component;
            return `${apiUrl}/project-quotes/${projectQuoteId}/stages/${projectStageId}/attachment`;
        }
    },
    mounted() {
        this.loadAttachments();
    },
    methods: {
        ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),
        ...mapActions(PROJECT_STAGES_TASKS_STORE, ['deleteProjectStageAttachment']),
        loadAttachments(recent = false) {
            const { projectStageAttachments } = this.projectStage;
            let attachments = projectStageAttachments;
            if (recent) {
                attachments = this.attachments.map((item) => {
                    return item.response;
                });
            }
            this.loadingAttachments = true;
            this.getAttachments({
                name: PROJECT_STAGES_CACHE,
                attachments,
                includeUrl: true
            })
                .then((values) => {
                const newValues = values.filter(Boolean);
                if (newValues.length) {
                    this.attachmentUrls = values;
                    this.imageAttachments = newValues.filter((item) => {
                        return this.isImageAttachment(item);
                    });
                    this.documentAttachments = newValues.filter((item) => {
                        return !this.isImageAttachment(item);
                    });
                }
            })
                .catch(() => { })
                .finally(() => { this.loadingAttachments = false; });
        },
        isStageButtonDisabled(stage) {
            let disabled = false;
            const { projectStageAttachments } = stage;
            const { attachments: recentAttachments } = this;
            switch (stage.projectStageStatus.id) {
                case ProjectStageStatusEnum.NOT_STARTED_POKED:
                    if (this.isTradesperson) {
                        disabled = true;
                    }
                    break;
                case ProjectStageStatusEnum.IN_PROGRESS:
                    if (!projectStageAttachments || !projectStageAttachments.length) {
                        disabled = true;
                    }
                    if (recentAttachments.length) {
                        disabled = false;
                    }
                    if (stage.projectStageTasks.length) {
                        stage.projectStageTasks.forEach((t) => {
                            if (!t.done)
                                disabled = true;
                        });
                    }
                    break;
                case ProjectStageStatusEnum.IN_PROGRESS_POKED:
                    if (!projectStageAttachments || !projectStageAttachments.length) {
                        disabled = true;
                    }
                    else if (this.isTradesperson) {
                        disabled = true;
                    }
                    break;
                case ProjectStageStatusEnum.STAGE_COMPLETE:
                    disabled = false;
                    break;
                case ProjectStageStatusEnum.PAYMENT_IN_PROCESS:
                    disabled = true;
                    break;
                default:
            }
            return disabled;
        },
        getTaskAmountWithServiceFee(amount) {
            const taskCostPercentage = (amount / this.projectStage.stageCost);
            const taskServiceFee = this.projectStageServiceFee * taskCostPercentage;
            const taskAmountWithServiceFee = amount + taskServiceFee;
            // eslint-disable-next-line no-restricted-globals
            return !isNaN(taskAmountWithServiceFee) ? taskAmountWithServiceFee : 0;
        },
        updateProjectStageStatus(stage) {
            if (stage.projectStageStatus.id === ProjectStageStatusEnum.STAGE_COMPLETE) {
                this.$emit('view-project-stage-invoice', stage);
            }
            else {
                this.loadAttachments(true);
                this.$emit('update-project-stage-status', this.projectStage, this.attachments);
            }
        },
        handleRemoveFile(file) {
            const component = this;
            const { id: projectQuoteId } = component.projectQuote;
            const { id: projectStageId } = component.projectStage;
            const { response } = file;
            const { id: projectStageAttachmentId } = response;
            // eslint-disable-next-line no-param-reassign
            file.isDeleting = true;
            this.deleteProjectStageAttachment({
                projectQuoteId,
                projectStageId,
                projectStageAttachmentId
            })
                .then(() => {
                const { attachments } = this;
                let newList = [];
                if (attachments) {
                    newList = attachments.filter((item) => item.uid !== file.uid);
                    this.attachments = newList;
                }
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Delete Attachment Error',
                    message: 'Error in deleting attachment. Please try again.',
                });
            });
        },
        onProgressUpload(event, file) {
            // eslint-disable-next-line no-param-reassign
            file.isLoading = true;
        },
        updateFileOnSuccess(response, file, fileList) {
            // eslint-disable-next-line no-param-reassign
            file.isLoading = false;
            this.attachments = fileList;
        },
        handleErrorFiles(err, file) {
            // eslint-disable-next-line no-param-reassign
            file.isError = true;
            this.$notify.error({
                title: 'Upload Error',
                message: err
                // message: 'Uploading files error occurred at the moment. Please try again.'
            });
        },
        isImageAttachment(file) {
            let { attachment: filename } = file;
            const { name } = file;
            if (!filename) {
                filename = name;
            }
            if (!filename)
                return false;
            const extension = filename.split('.')[filename.split('.').length - 1];
            return ImageFileTypes.includes(extension.toLowerCase());
        },
        isPdfFile(filename) {
            return filename && filename.indexOf('.pdf') > -1;
        }
    }
});

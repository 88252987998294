import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import StringHelper from '@/core/helpers/string.helper';
import { ProjectStageStatusEnum } from '@/core/packages/shared-library';
import { PAYMENTS_STORE } from '../../../store/modules/payments';
export default defineComponent({
    name: 'project-stage-deposit-all-modal',
    components: {
        BaseModal
    },
    props: {
        show: Boolean,
        loading: Boolean,
        paymentProviders: Array,
        projectQuote: Object,
        project: Object
    },
    emits: ['create-project-stage-deposit-all', 'close'],
    data: () => {
        return {
            paymentFormRules: {
                method: [
                    { required: true, message: 'Select Bank.', trigger: 'change' },
                ]
            },
            loadBankData: false,
            unpaidStatuses: [ProjectStageStatusEnum.NOT_STARTED, ProjectStageStatusEnum.NOT_STARTED_POKED]
        };
    },
    computed: {
        totalAmountToPay() {
            const { projectQuote } = this;
            const { projectStages } = projectQuote;
            const unpaidStages = projectStages.filter((stage) => this.unpaidStatuses.includes(stage.projectStageStatus.id));
            const stages = unpaidStages.map((projectStage) => {
                const { projectStageServiceFee: serviceFee, stageCost } = projectStage;
                const rawTotalCost = stageCost + serviceFee;
                return { rawTotalCost };
            });
            let totalCostSumOfStages = 0;
            if (stages) {
                const totalCostPerStage = stages.map((stage) => stage.rawTotalCost);
                totalCostSumOfStages = totalCostPerStage.reduce((previousValue, currentValue) => {
                    return previousValue + currentValue;
                }, 0);
            }
            return totalCostSumOfStages;
        },
        paymentReference() {
            const { project } = this;
            return StringHelper.cleanPaymentReference(project.refId);
        },
        ...mapState(PAYMENTS_STORE, {
            paymentForm: (state) => state.paymentForm,
        }),
        hasPaidAndUnpaidProjectStages() {
            const { projectQuote } = this;
            const { projectStages } = projectQuote;
            const paidStatuses = [
                ProjectStageStatusEnum.IN_PROGRESS,
                ProjectStageStatusEnum.STAGE_COMPLETE
            ];
            const unpaid = projectStages.filter((projectStage) => this.unpaidStatuses.includes(projectStage.projectStageStatus.id));
            const paid = projectStages.filter((projectStage) => paidStatuses.includes(projectStage.projectStageStatus.id));
            return (unpaid.length > 0 && paid.length > 0) || paid.length === projectStages.length;
        }
    },
    methods: {
        showAccountDetails(selectedBank) {
            if (selectedBank.length) {
                this.loadBankData = true;
                this.paymentForm.aspspId = selectedBank;
                this.paymentForm.paymentAmount.value = parseFloat(this.totalAmountToPay.toFixed(2));
            }
        },
        cancel() {
            this.loadBankData = false;
            this.$refs.paymentForm.resetFields();
            this.$emit('close');
        },
        submit() {
            const { project, paymentReference, paymentForm, projectQuote, $refs } = this;
            const { projectAccount } = project;
            const { projectStages } = projectQuote;
            const unpaidStages = projectStages.filter((stage) => this.unpaidStatuses.includes(stage.projectStageStatus.id));
            const stageData = unpaidStages.map((projectStage) => {
                const { projectStageServiceFee } = projectStage;
                return {
                    projectStageId: projectStage.id,
                    stageServiceFee: projectStageServiceFee
                };
            });
            paymentForm.destination.id = projectAccount.accountId;
            paymentForm.paymentReference = paymentReference;
            paymentForm.projectStageDeposits = stageData;
            delete paymentForm.projectStageId;
            // eslint-disable-next-line consistent-return
            $refs.paymentForm.validate((valid) => {
                if (valid) {
                    this.$emit('create-project-stage-deposit-all', { ...this.paymentForm });
                }
                else {
                    return false;
                }
            });
        },
    }
});

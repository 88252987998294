import { defineComponent } from 'vue';
export default defineComponent({
    name: 'project-stage-invoice-component',
    props: {
        invoiceInformation: Object
    },
    data() {
        return {
            phaseInfo: null
        };
    },
    watch: {
        invoiceInformation: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value) {
                    this.phaseInfo = value;
                }
            }
        }
    },
});

import moment from 'moment';
import { defineComponent } from 'vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { ProjectStageStatusEnum } from '@/core/packages/shared-library';
import CurrencyFilter from '@/filters/currency.filter';
import ProjectStageInvoiceComponent from '@/modules/project-stage-invoice/components/ProjectStageInvoiceComponent.vue';
const LABOUR = 'labour';
export default defineComponent({
    name: 'project-stage-invoice-modal',
    emits: ['download-project-stage-invoice', 'close'],
    components: {
        BaseModal,
        ProjectStageInvoiceComponent
    },
    props: {
        show: Boolean,
        loading: Boolean,
        selectedProjectStage: Object,
        projectQuote: Object,
        selectedProject: Object
    },
    computed: {
        invoiceInformation() {
            const { selectedProject, projectQuote, selectedProjectStage } = this;
            const { projectQuoteCreator } = projectQuote;
            const { firstName, lastName, userBusinessDetails } = projectQuoteCreator;
            const { businessName, companyName, taxReferenceNumber } = userBusinessDetails;
            const { projectStageServiceFee: stageType, subTotal, vat, projectStageStatus, totalDuration } = selectedProjectStage;
            let subTotalWithVat = 0;
            const filter = CurrencyFilter;
            const stageStatus = projectStageStatus.id === ProjectStageStatusEnum.STAGE_COMPLETE
                ? 'Completed' : 'In Progress';
            if (subTotal && vat) {
                const vatAmount = (parseFloat(vat) * subTotal) / 100;
                subTotalWithVat = vatAmount;
            }
            const data = {
                companyName: (companyName || businessName) || `${firstName} ${lastName}`,
                taxNumber: taxReferenceNumber,
                recipient: `${firstName.charAt(0)}${lastName.charAt(0)}`,
                invoiceId: selectedProjectStage.refId,
                projectId: selectedProject.refId,
                projectStageName: selectedProjectStage.name,
                projectStageStatus: stageStatus,
                dateGenerated: moment().format('DD/MM/YYYY'),
                projectStageTasks: this.projectStageTasks,
                subTotal: filter.formatToCurrency(subTotal),
                subTotalWithVat: filter.formatToCurrency(subTotalWithVat),
                serviceFee: filter.formatToCurrency(selectedProjectStage.serviceFee),
                totalCost: filter.formatToCurrency(selectedProjectStage.stageCost + selectedProjectStage.serviceFee),
                totalDuration: this.convertToDecimal(totalDuration),
                vat,
                isStageLabour: stageType === LABOUR
            };
            return data;
        },
        projectStageTasks() {
            const { selectedProjectStage } = this;
            const { projectStageTasks } = selectedProjectStage;
            const filter = CurrencyFilter;
            return projectStageTasks.map((projectStageTask) => {
                const duration = projectStageTask.duration
                    ? this.convertToDecimal(projectStageTask.duration) : '';
                const amount = projectStageTask.amount
                    ? filter.formatToCurrency(projectStageTask.amount) : '';
                const quantity = projectStageTask.quantity
                    ? this.convertToDecimal(projectStageTask.quantity) : '';
                return {
                    ...projectStageTask,
                    duration,
                    quantity,
                    amount
                };
            });
        }
    },
    methods: {
        download() {
            this.$emit('download-project-stage-invoice', { ...this.invoiceInformation });
        },
        cancel() {
            this.$emit('close');
        },
        convertToDecimal(value) {
            let numberValue = parseFloat(value);
            if (Number.isNaN(numberValue)) {
                numberValue = 0;
            }
            return numberValue.toFixed(2);
        }
    }
});

import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { ProjectStageStatusEnum } from '@/core/packages/shared-library';
export default defineComponent({
    name: 'project-stage-task-filter',
    emits: ['on-pay-all-project-stages'],
    props: {
        filters: Object,
        availableFilters: Array,
        projectAccountBalance: {
            type: Number,
            default: 0
        },
        projectQuote: Object
    },
    data: () => {
        return {
            unpaidStatuses: [ProjectStageStatusEnum.NOT_STARTED, ProjectStageStatusEnum.NOT_STARTED_POKED]
        };
    },
    computed: {
        ...mapGetters(['isProjectOwner']),
        formEntity() {
            return this.filters;
        },
        hasUnpaidProjectStages() {
            const { projectQuote } = this;
            const { projectStages } = projectQuote;
            const unpaid = projectStages.filter((projectStage) => this.unpaidStatuses.includes(projectStage.projectStageStatus.id));
            return projectStages.length > 0 && unpaid.length > 1;
        },
        hasPaidAndUnpaidProjectStages() {
            const { projectQuote } = this;
            const { projectStages } = projectQuote;
            const paidStatuses = [
                ProjectStageStatusEnum.IN_PROGRESS,
                ProjectStageStatusEnum.STAGE_COMPLETE
            ];
            const unpaid = projectStages.filter((projectStage) => this.unpaidStatuses.includes(projectStage.projectStageStatus.id));
            const paid = projectStages.filter((projectStage) => paidStatuses.includes(projectStage.projectStageStatus.id));
            return (unpaid.length > 0 && paid.length > 0) || paid.length === projectStages.length;
        }
    },
    methods: {
        onPayAllProjectStages() {
            this.$emit('on-pay-all-project-stages');
        }
    }
});

import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import ProjectStagesTasksCardSkeleton from '@/core/components/project-stages-tasks/placeholder/ProjectStagesTasksCardSkeleton.vue';
import ProjectStagesTasksContainerSlot from '@/core/components/project-stages-tasks/ProjectStagesTasksContainerSlot.vue';
import RateUserModal from '@/core/components/ratings/RateUserModal.vue';
import { ProjectJobStatusEnum, ProjectStageStatusEnum } from '@/core/packages/shared-library';
import ProjectStageTaskCard from '@/modules/project-stages-tasks/components/ProjectStageTaskCard.vue';
import ProjectStageTaskFilter from '@/modules/project-stages-tasks/components/ProjectStageTaskFilter.vue';
import ProjectStageApproveReleaseModal from '@/modules/project-stages-tasks/modals/ProjectStageApproveReleaseModal.vue';
import ProjectStageDepositAllModal from '@/modules/project-stages-tasks/modals/ProjectStageDepositAllModal.vue';
import ProjectStageDepositModal from '@/modules/project-stages-tasks/modals/ProjectStageDepositModal.vue';
import ProjectStageInvoiceModal from '@/modules/project-stages-tasks/modals/ProjectStageInvoiceModal.vue';
import { PROJECTS_ROUTE } from '@/modules/projects/routes';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { PAYMENTS_STORE } from '@/store/modules/payments';
// eslint-disable-next-line import/no-cycle
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';
import { PROJECT_STAGES_TASKS_STORE } from '@/store/modules/project-quotes/submodules/project-stages-tasks';
import { PROJECT_STAGE_DEPOSITS_STORE } from '@/store/modules/project-stage-deposits';
import { PROJECT_STAGES_STORE } from '@/store/modules/project-stages';
// eslint-disable-next-line import/no-cycle
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'project-stages-tasks-page',
    components: {
        ProjectStagesTasksCardSkeleton,
        ProjectStagesTasksContainerSlot,
        ProjectStageTaskCard,
        ProjectStageTaskFilter,
        ProjectStageDepositModal,
        ProjectStageApproveReleaseModal,
        RateUserModal,
        ProjectStageInvoiceModal,
        ProjectStageDepositAllModal
    },
    data: () => {
        return {
            selectedProject: null,
            selectedProjectStage: null,
            isLoadingProjectInfo: null,
            verifyingStage: 0,
            filterForm: {
                status: null,
                sort: 0,
                filter: 0,
            },
            openUserRatingModal: false,
            openProjectStageDepositModal: false,
            openProjectStageApproveReleaseDepositModal: false,
            loadingProjectStageDeposit: false,
            loadingProjectStageApproveReleaseDeposit: false,
            availableFilters: [
                { label: 'All', value: null },
                { label: 'Not Started', value: [ProjectStageStatusEnum.NOT_STARTED, ProjectStageStatusEnum.NOT_STARTED_POKED] },
                { label: 'In Progress', value: [ProjectStageStatusEnum.IN_PROGRESS, ProjectStageStatusEnum.IN_PROGRESS_POKED] },
                { label: 'Stage Complete', value: [ProjectStageStatusEnum.STAGE_COMPLETE] },
                { label: 'Cancelled', value: [ProjectStageStatusEnum.CANCELLED] }
            ],
            openProjectStageInvoiceModal: false,
            loadingProjectStageInvoice: false,
            projectAccountBalance: 0,
            openProjectStageAllDepositModal: false,
            loadingProjectStageAllDeposit: false
        };
    },
    beforeRouteEnter(to, from, next) {
        next(async (vm) => {
            await vm.initialize();
            vm.checkIfUserAllowedToAccess();
        });
    },
    computed: {
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        ...mapGetters(PAYMENTS_STORE, ['paymentProviders']),
        projectQuote() {
            if (this.selectedProject?.projectQuotes?.length) {
                return this.selectedProject?.projectQuotes[0];
            }
            return null;
        },
        confirmedProjectQuote() {
            const quotes = this.selectedProject?.projectQuotes;
            const confirmedQuote = quotes.filter((quote) => quote.agreeTermsConsumerDisclosure === true);
            if (confirmedQuote.length) {
                return confirmedQuote[0];
            }
            return null;
        },
        getFilteredProjectStages() {
            if (this.confirmedProjectQuote) {
                let stages = this.confirmedProjectQuote.projectStages;
                if (this.filterForm.status !== null && Array.isArray(stages) && stages.length) {
                    stages = stages.filter((stage) => this.filterForm.status.includes(stage.projectStageStatus?.id));
                }
                return stages || [];
            }
            return [];
        },
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getProjectByRefId', 'setSelectedProjectJobStatus']),
        ...mapActions(PROJECT_STAGES_TASKS_STORE, [
            'updateProjectStage',
            'updateProjectStageStatus',
            'updateProjectStageTaskStatus',
            'checkAllProjectStagesIfCompleted'
        ]),
        ...mapActions(PAYMENTS_STORE, ['getPaymentProviders']),
        ...mapActions(PROJECT_STAGE_DEPOSITS_STORE, [
            'createProjectStageDeposit',
            'approveReleaseProjectStageDeposit',
            'createAllProjectStageDeposit'
        ]),
        ...mapActions(USERS_STORE, ['getUserCustomerAccount', 'getUserCustomerAccountDetails', 'getUserRatingsPageList', 'createUserRating', 'getUserRatingSummary']),
        ...mapActions(PROJECT_QUOTES_STORE, ['getTotalStageCost', 'calculateServiceFee']),
        ...mapActions(PROJECT_STAGES_STORE, ['getProjectStage', 'createProjectStageInvoice']),
        async initialize() {
            const { projectRefId } = this.$route.params;
            this.isLoadingProjectInfo = true;
            this.selectedProject = await this.getProjectByRefId(projectRefId);
            if (this.selectedProject) {
                this.loadPaymentProviders();
                this.getProjectAccountBalance();
                this.setSelectedProjectJobStatus(this.selectedProject?.projectJobStatus);
                this.initiateProjectStagePusherNotification();
                if (this.confirmedProjectQuote?.projectStages) {
                    const getProjectStageServiceFeePromises = [];
                    this.confirmedProjectQuote.projectStages.map((projectStage, i) => {
                        getProjectStageServiceFeePromises.push(this.getProjectStageServiceFee(i));
                        return projectStage;
                    });
                    const getProjectStageServiceFeeResponses = await Promise.all(getProjectStageServiceFeePromises);
                    if (getProjectStageServiceFeeResponses) {
                        this.confirmedProjectQuote.projectStages = this.confirmedProjectQuote.projectStages.map((projectStage, i) => {
                            const projectStageWithServiceFee = { ...projectStage, projectStageServiceFee: getProjectStageServiceFeeResponses[i] };
                            return projectStageWithServiceFee;
                        });
                    }
                }
            }
            else {
                this.$notify.error({
                    message: 'Project not found.'
                });
                this.$router.push({ name: PROJECTS_ROUTE });
            }
            this.isLoadingProjectInfo = false;
            this.isAllProjectStagesCompleted();
        },
        initiateProjectStagePusherNotification() {
            const projectChannel = this.$pusher.subscribe(`private-project-update-${this.selectedProject.id}`);
            projectChannel.bind('pusher:subscription_succeeded', () => {
                console.info('Subscription of project update channel succeeded.');
            });
            projectChannel.bind('pusher:subscription_error', () => {
                // console.error(error);
                // re-authenticate subscription event
                this.$pusher.subscribe(`private-project-update-${this.selectedProject.id}`);
            });
            projectChannel.bind('project.stage-update', (projectStage) => {
                // console.info('project stage update');
                const { projectStageStatus, projectStageAttachments, projectStageTasks } = projectStage;
                const findProjectStage = this.getFilteredProjectStages.find((record) => record.id === projectStage.id);
                const foundProjectStageIndex = this.getFilteredProjectStages.indexOf(findProjectStage);
                if (findProjectStage) {
                    this.getFilteredProjectStages[foundProjectStageIndex] = {
                        ...this.getFilteredProjectStages[foundProjectStageIndex],
                        projectStageStatus,
                        projectStageAttachments,
                        projectStageTasks
                    };
                }
            });
        },
        async initiateProjectStageDeposit(projectStage) {
            this.selectedProjectStage = projectStage;
            this.openProjectStageDepositModal = true;
        },
        async initiateApproveReleaseProjectStage(projectStage) {
            this.selectedProjectStage = projectStage;
            this.openProjectStageApproveReleaseDepositModal = true;
        },
        async getProjectStageServiceFee(stageIndex) {
            const totalStageCost = await this.getTotalStageCost(this.confirmedProjectQuote.projectStages);
            const totalServiceFee = await this.calculateServiceFee({ projectId: this.selectedProject?.id, projectStages: this.confirmedProjectQuote.projectStages });
            const stageCostPercentage = this.confirmedProjectQuote.projectStages[stageIndex].stageCost / totalStageCost;
            const stageServiceFee = totalServiceFee * stageCostPercentage;
            return parseFloat(stageServiceFee.toFixed(2));
        },
        checkIfUserAllowedToAccess() {
            if (this.isTradesperson && (this.confirmedProjectQuote == null
                || this.confirmedProjectQuote.projectQuoteCreator?.id !== this.authenticatedUser?.userId)) {
                this.$router.push('/unauthorize');
                return;
            }
            if (this.isProjectOwner && this.authenticatedUser?.userId !== this.selectedProject?.projectCreator?.id) {
                this.$router.push('/unauthorize');
            }
        },
        async executeCreateUserRating(payload) {
            this.createUserRating(payload)
                .then(() => {
                this.$notify.success({
                    message: 'Rating successfully submitted.'
                });
            }).catch(() => {
                this.$notify.error({
                    message: 'Rating submission failed.'
                });
            })
                .finally(() => {
                this.openUserRatingModal = false;
            });
        },
        async viewProjectStageInvoice(stage) {
            this.openProjectStageInvoiceModal = true;
            this.selectedProjectStage = stage;
        },
        async downloadProjectStageInvoice(invoiceInformation) {
            this.loadingProjectStageInvoice = true;
            await this.createProjectStageInvoice({
                projectQuoteId: this.confirmedProjectQuote.id,
                projectStageId: this.selectedProjectStage.id,
                invoiceInformation: { ...invoiceInformation }
            })
                .then((response) => {
                if (response.url) {
                    window.open(response.url, '_blank');
                }
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error Downloading Project Stage Invoice',
                    message: 'Error occurred when downloading stage invoice at the moment. Please try again.'
                });
            })
                .finally(() => {
                this.loadingProjectStageInvoice = false;
            });
        },
        async executeUpdateProjectStageTaskStatus({ projectStageTaskStatus, projectStageTaskId, projectStageId }) {
            const projectQuoteId = this.confirmedProjectQuote?.id;
            this.updateProjectStageTaskStatus({
                projectQuoteId, projectStageId, projectStageTaskId, projectStageTaskStatus
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error Updating Stage Task/Materials',
                    message: 'Error occurred when updating stage task/materials at the moment. Please try again.'
                });
            });
        },
        async executeUpdateProjectStageStatus(stage, recentAttachments) {
            const newProjectStageStatusId = this.getNewProjectStageStatus(stage, recentAttachments);
            if (newProjectStageStatusId) {
                const projectQuoteId = this.confirmedProjectQuote?.id;
                const projectStageId = stage.id;
                const formData = {
                    projectStageStatusId: newProjectStageStatusId,
                };
                this.updateProjectStageStatus({ projectQuoteId, projectStageId, formData })
                    .then((response) => {
                    if (response) {
                        const { projectStageStatus } = response;
                        this.getFilteredProjectStages[this.getFilteredProjectStages.indexOf(stage)] = { ...this.getFilteredProjectStages[this.getFilteredProjectStages.indexOf(stage)], projectStageStatus };
                        /* (this as any).$notify.success({
                          title: 'Project Stage Request Successful.',
                          message: 'You successfully submitted project stage action.'
                        }); */
                    }
                })
                    .catch(() => {
                    this.$notify.error({
                        title: 'Error Updating Stage Status',
                        message: 'Error occurred on updating stage status at the moment. Please try again.',
                    });
                });
            }
            else if (this.isProjectOwner
                && (stage?.projectStageStatus?.id === ProjectStageStatusEnum.NOT_STARTED
                    || stage?.projectStageStatus?.id === ProjectStageStatusEnum.NOT_STARTED_POKED)) {
                try {
                    this.verifyingStage = stage?.id;
                    const getUpdatedProjectStage = await this.getProjectStage({ projectQuoteId: this.confirmedProjectQuote.id, projectStageId: stage?.id });
                    if (getUpdatedProjectStage && this.isProjectStageNotStarted(getUpdatedProjectStage.projectStageStatus?.id)) {
                        this.initiateProjectStageDeposit(stage);
                    }
                    else {
                        this.$notify.error({
                            message: 'This project stage has already been started. Please refresh the page to view the updated status of the stage.'
                        });
                    }
                    this.verifyingStage = 0;
                }
                catch (e) {
                    this.$notify.error({
                        message: 'Error encountered on fetching the project stage details.'
                    });
                }
            }
            else if (this.isProjectOwner && (stage.projectStageStatus.id === ProjectStageStatusEnum.IN_PROGRESS
                || stage.projectStageStatus?.id === ProjectStageStatusEnum.IN_PROGRESS_POKED)) {
                this.initiateApproveReleaseProjectStage(stage);
            }
        },
        async executeCreateProjectStageDeposit(payload) {
            this.loadingProjectStageDeposit = true;
            // eslint-disable-next-line no-param-reassign
            delete payload.projectStageDeposits;
            this.createProjectStageDeposit(payload).then((response) => {
                if (response && response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
            })
                .catch((error) => {
                this.$notify.error({
                    title: 'Error Stage Deposit',
                    message: error && error.response && error.response.data ? error.response.data.message : 'Error occurred. Unable to send deposit stage from your account at this time. Please try again.'
                });
            })
                .finally(() => {
                this.loadingProjectStageDeposit = false;
            });
        },
        async executeCreateAllProjectStageDeposit(payload) {
            this.loadingProjectStageAllDeposit = true;
            this.createAllProjectStageDeposit(payload).then((response) => {
                if (response && response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
            })
                .catch((error) => {
                this.$notify.error({
                    title: 'Error Stage Deposit',
                    message: error && error.response && error.response.data ? error.response.data.message : 'Error occurred. Unable to send deposit stage from your account at this time. Please try again.'
                });
            })
                .finally(() => {
                this.loadingProjectStageAllDeposit = false;
            });
        },
        async executeUpdateProjectStageCompleted(projectStage) {
            const projectQuoteId = this.confirmedProjectQuote?.id;
            const projectStageId = projectStage.id;
            const formData = {
                projectStageStatusId: ProjectStageStatusEnum.STAGE_COMPLETE,
            };
            this.updateProjectStageStatus({ projectQuoteId, projectStageId, formData })
                .then((response) => {
                if (response) {
                    const { projectStageStatus } = response;
                    this.getFilteredProjectStages[this.getFilteredProjectStages.indexOf(projectStage)] = { ...this.getFilteredProjectStages[this.getFilteredProjectStages.indexOf(projectStage)], projectStageStatus };
                    /* (this as any).$notify.success({
                      title: 'Project Stage Request Successful.',
                      message: 'You successfully submitted project stage action.'
                    }); */
                }
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error Updating Stage Status',
                    message: 'Error occurred on updating stage status at the moment. Please try again.',
                });
            });
        },
        async executeApproveReleaseProjectStageDeposit() {
            try {
                this.loadingProjectStageApproveReleaseDeposit = true;
                const { projectQuoteCreator } = this.confirmedProjectQuote;
                const userCustomerAccount = await this.getUserCustomerAccount(projectQuoteCreator.id);
                const stageServiceFee = parseFloat(this.selectedProjectStage.projectStageServiceFee.toFixed(2));
                const formData = {
                    stage: this.selectedProjectStage,
                    userCustomerAccount,
                    stageServiceFee,
                    projectId: this.selectedProject?.id,
                    projectAccount: this.selectedProject.projectAccount
                };
                const approveReleaseStageDepositResponse = await this.approveReleaseProjectStageDeposit(formData);
                if (approveReleaseStageDepositResponse) {
                    this.$notify.success({
                        message: 'Successfully released the payment in Stage',
                    });
                    await this.executeUpdateProjectStageCompleted(this.selectedProjectStage);
                    await this.isAllProjectStagesCompleted();
                    this.openProjectStageApproveReleaseDepositModal = false;
                }
                else {
                    this.$notify.error({
                        title: 'Error Approve Release Deposit',
                        message: 'Error occurred. Unable to release of funds to TP/Your account has no sufficient funds at this time. Please try again.'
                    });
                }
                this.loadingProjectStageApproveReleaseDeposit = false;
            }
            catch (error) {
                this.$notify.error({
                    title: 'Error Approve Release Deposit',
                    message: 'Error occurred. Unable to release of funds to TP/Your account has no sufficient funds at this time. Please try again.'
                });
                this.loadingProjectStageApproveReleaseDeposit = false;
            }
        },
        getNewProjectStageStatus(stage, recentAttachments) {
            if (!stage) {
                this.$notify.error({
                    message: 'No stages available.'
                });
                return false;
                // no stages defined
            }
            let newProjectStageStatusId = null;
            switch (stage.projectStageStatus?.id) {
                case ProjectStageStatusEnum.NOT_STARTED:
                    if (this.isTradesperson) {
                        newProjectStageStatusId = ProjectStageStatusEnum.NOT_STARTED_POKED;
                    }
                    break;
                case ProjectStageStatusEnum.IN_PROGRESS:
                    if ((stage && stage.projectStageAttachments) || recentAttachments.length) {
                        if (this.isTradesperson) {
                            newProjectStageStatusId = ProjectStageStatusEnum.IN_PROGRESS_POKED;
                        }
                    }
                    break;
                default:
            }
            return newProjectStageStatusId;
        },
        isProjectStageNotStarted(projectStageStatusId) {
            return projectStageStatusId === ProjectStageStatusEnum.NOT_STARTED || projectStageStatusId === ProjectStageStatusEnum.NOT_STARTED_POKED;
        },
        async isAllProjectStagesCompleted() {
            const checkAllStagesResponse = await this.checkAllProjectStagesIfCompleted(this.confirmedProjectQuote?.projectStages);
            if (!checkAllStagesResponse && this.isProjectOwner) {
                const checkPOHasRatedResponse = await this.getUserRatingsPageList({
                    userId: this.confirmedProjectQuote.projectQuoteCreator?.id,
                    filter: {
                        refId: this.selectedProject?.id,
                    }
                });
                if (!checkPOHasRatedResponse?.data?.length) {
                    this.openUserRatingModal = true;
                }
            }
        },
        async getProjectAccountBalance() {
            const { selectedProject, user } = this;
            const { projectAccount } = selectedProject;
            await this.getUserCustomerAccountDetails({
                userId: user.id,
                accountId: projectAccount.accountId
            })
                .then((response) => {
                const { availableBalance } = response;
                this.projectAccountBalance = availableBalance;
            })
                .catch(() => { });
        },
        loadPaymentProviders() {
            const { selectedProject } = this;
            const { projectJobStatus } = selectedProject;
            if (projectJobStatus.id !== ProjectJobStatusEnum.COMPLETED) {
                this.getPaymentProviders()
                    .catch(() => {
                    this.$notify.error({
                        message: 'Error fetching payment providers at the moment. Please try again.',
                    });
                });
            }
        },
        async onPayAllProjectStages() {
            this.openProjectStageAllDepositModal = true;
        }
    },
});

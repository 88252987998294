import { Delete, Plus } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import ImageDynamicLayout from '@/core/components/ui/ImageDynamicLayout.vue';
import ImageFailedLoading from '@/core/components/ui/ImageFailedLoading.vue';
import ImageLoading from '@/core/components/ui/ImageLoading.vue';
import { ImageFileTypes } from '@/core/helpers/file.helper';
import { getLocalStorageWithExpiry } from '@/core/utils/common';
import { AUTHENTICATION_STORE } from '../../../store/modules/authentication';
import { AUTH_TOKEN } from '../../constants';
export default defineComponent({
    name: 'RateUserDialog',
    emits: ['close', 'submit'],
    components: {
        BaseModal,
        Plus,
        Delete,
        ImageDynamicLayout,
        ImageLoading,
        ImageFailedLoading
    },
    props: {
        visible: Boolean,
        toUserId: {
            type: Number,
            required: true,
        },
        refId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        uploadHeaders() {
            const token = getLocalStorageWithExpiry(AUTH_TOKEN);
            return {
                Authorization: `Bearer ${token}`
            };
        }
    },
    data: () => {
        return {
            ratings: [
                {
                    value: 1,
                    label: 'Very Poor'
                },
                {
                    value: 2,
                    label: 'Poor'
                },
                {
                    value: 3,
                    label: 'Average'
                },
                {
                    value: 4,
                    label: 'Good'
                },
                {
                    value: 5,
                    label: 'Excellent'
                }
            ],
            ratingForm: {
                fromUserId: 0,
                toUserId: 0,
                value: null,
                comments: '',
                attachments: [],
                refId: 0,
                type: '',
            },
            fileList: [],
            apiUrl: process.env.VUE_APP_API_URL,
            failedUpload: false,
            isLoading: false,
            isDeleting: false,
            imageFileTypes: ImageFileTypes,
            submitted: false
        };
    },
    methods: {
        ...mapActions('user-ratings', ['createUserRating']),
        onClickRating(rating) {
            this.ratingForm.value = rating;
        },
        onClose() {
            this.$emit('close');
        },
        onSubmit() {
            const payload = {
                fromUserId: this.authenticatedUser.userId,
                toUserId: this.toUserId,
                rating: {
                    ...this.ratingForm,
                    refId: this.refId,
                    type: 'project',
                    fromUserId: this.authenticatedUser.userId,
                },
            };
            this.$emit('submit', payload);
            this.submitted = true;
        },
        onBeforeUpload(file) {
            const name = file.name || '';
            const ext = name.split('.').pop();
            const imageTypes = this.imageFileTypes;
            let allow = false;
            this.failedUpload = false;
            if (imageTypes.indexOf(ext) !== -1) {
                allow = true;
            }
            if (!allow) {
                this.failedUpload = true;
            }
            return allow;
        },
        fileIsImage(file) {
            let { attachment: filename } = file;
            const { name } = file;
            const imageTypes = this.imageFileTypes;
            if (!filename) {
                filename = name;
            }
            if (!filename)
                return false;
            const extension = filename.split('.')[filename.split('.').length - 1];
            return imageTypes.includes(extension.toLowerCase());
        },
        async updateFileOnSuccess(event, file, fileList) {
            const { ratingForm } = this;
            const currentFile = file;
            const { response } = currentFile;
            this.fileList = fileList;
            currentFile.src = response.src;
            currentFile.isLoading = false;
            delete response.src;
            ratingForm.attachments.push(response);
        },
        onProgressUpload(event, file) {
            // eslint-disable-next-line no-param-reassign
            file.isLoading = true;
        },
        async handleRemoveFile(file) {
            const component = this;
            const currentFile = file;
            const { response } = file;
            const { ratingForm, fileList } = component;
            const { attachments } = ratingForm;
            const attachment = response?.attachment;
            let newList = [];
            currentFile.isDeleting = true;
            if (!attachment) {
                newList = fileList.filter((item) => item.attachment !== file.attachment);
                component.fileList = newList;
                component.ratingForm.attachments = attachments.filter((a) => a.attachment !== file.attachment);
            }
            if (attachment) {
                newList = fileList.filter((item) => item.uid !== file.uid);
                component.fileList = newList;
                component.ratingForm.attachments = attachments.filter((a) => a.attachment !== attachment);
            }
        },
        handleErrorFiles(err) {
            this.$notify.error({
                title: 'Upload Error',
                message: err || 'Error uploading file'
            });
        },
    }
});

import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import StringHelper from '@/core/helpers/string.helper';
import { PAYMENTS_STORE } from '../../../store/modules/payments';
export default defineComponent({
    name: 'project-stage-deposit-modal',
    components: {
        BaseModal
    },
    props: {
        show: Boolean,
        loading: Boolean,
        paymentProviders: Array,
        selectedProjectStage: Object,
        projectQuote: Object,
        selectedProject: Object
    },
    emits: ['create-project-stage-deposit', 'close'],
    data: () => {
        return {
            paymentFormRules: {
                method: [
                    { required: true, message: 'Select Bank.', trigger: 'change' },
                ]
            },
            loadBankData: false,
        };
    },
    computed: {
        paymentReference() {
            const { selectedProjectStage } = this;
            return StringHelper.cleanPaymentReference(selectedProjectStage.refId);
        },
        ...mapState(PAYMENTS_STORE, {
            paymentForm: (state) => state.paymentForm,
        }),
        stageIndex() {
            return this.projectQuote.projectStages.indexOf(this.selectedProjectStage);
        },
        totalStageCost() {
            return this.selectedProjectStage.stageCost + this.selectedProjectStage.projectStageServiceFee;
        },
    },
    watch: {
        stageIndex: {
            immediate: true,
            handler: 'getStageServiceFee'
        }
    },
    methods: {
        showAccountDetails(selectedBank) {
            if (selectedBank.length) {
                this.loadBankData = true;
                this.paymentForm.aspspId = selectedBank;
                this.paymentForm.paymentAmount.value = parseFloat(this.totalStageCost.toFixed(2));
            }
        },
        cancel() {
            this.loadBankData = false;
            this.$refs.paymentForm.resetFields();
            this.$emit('close');
        },
        submit() {
            this.paymentForm.projectStageId = this.projectQuote.projectStages[this.stageIndex].id;
            this.paymentForm.destination.id = this.selectedProject?.projectAccount?.accountId;
            this.paymentForm.paymentReference = `${this.paymentReference}`;
            const stageServiceFee = parseFloat(parseFloat(this.selectedProjectStage.projectStageServiceFee).toFixed(2));
            // eslint-disable-next-line consistent-return
            this.$refs.paymentForm.validate((valid) => {
                if (valid) {
                    this.$emit('create-project-stage-deposit', { ...this.paymentForm, stageServiceFee });
                }
                else {
                    return false;
                }
            });
        },
    }
});
